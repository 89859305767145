import { motion } from 'framer-motion';
import { Tooltip } from 'react-tooltip';
import { FaBuilding } from "react-icons/fa";
import { IoMdTimer } from "react-icons/io";

export const ExperienceItem = ({ experience }) => {
    const work = experience.works[0] || {}
    return (
        <>
            <motion.div
                className="app__skills-exp-item "
            >
                <div className="app__skills-exp-year">
                    <p className="bold-text"> {(new Date(experience.date)).getFullYear()}</p>
                </div>
                <motion.div className="app__skills-exp-works">
                    {

                        <motion.div
                            key={work._id}
                            whileInView={{ opacity: [0, 1] }}
                            className="app__skills-exp-work"
                            transition={{ duration: 0.5 }}
                        >
                            <h4 className="bold-text"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={work.description}
                                data-tooltip-place='right-start'
                                data-tooltip-class-name='skills-tooltip'

                            > {work.role}
                            </h4>
                            <p className="p-text">
                                <FaBuilding /> {work.company}
                            </p>
                            <p className="p-text">
                                <IoMdTimer /> {work.duration}
                            </p>
                        </motion.div>

                        // experience.works.map((work) => (
                        //     <>
                        //         <motion.div
                        //             key={work._id}
                        //             whileInView={{ opacity: [0, 1] }}
                        //             transition={{ duration: 0.5 }}

                        //             data-tooltip-id="my-tooltip"
                        //             data-tooltip-content={work.description}
                        //             className="app__skills-exp-work"
                        //         >
                        //             <h4 className="bold-text"> {work.name} </h4>
                        //             <p className="p-text">
                        //                 {work.company}
                        //             </p>
                        //             <p className="p-text">
                        //                 - {work.duration}
                        //             </p>
                        //         </motion.div>
                        //     </>
                        // ))

                    }
                    <Tooltip id="my-tooltip" />
                </motion.div>
            </motion.div>
        </>
    )
}
import { urlFor } from '../../../data/client'
import { motion } from 'framer-motion';
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { FaBuilding, FaLinkedin, FaNetworkWired } from "react-icons/fa";
import { IoMail } from "react-icons/io5";

export const TestimonialCard = ({ testimonial, onHandleLeftClick, onHandleRightClick }) => {

    return (
        <>
            <div
                className="app__testimonial-item app__flex">
                <motion.img src={urlFor(testimonial.imgUrl)} alt={'Testimonial'}
                    whileInView={{ opacity: [0, 1] }}
                    transition={{ duration: 0.3, ease: 'easeInOut' }}
                >

                </motion.img>
                <div className="app__testimonial-content">
                    <p className="p-text">
                        "{testimonial.feedback}"
                    </p>
                    <div>
                        <div style={{ cursor: 'default' }} >
                            <h4 className="bold-text people-name">
                                {testimonial.name} -
                                <div className='social-media'>
                                    {testimonial.linkedInUrl && (<a title='LinkedIn' target="_blank" rel="noreferrer" href={testimonial.linkedInUrl} ><FaLinkedin /></a>)}
                                    {testimonial.email && (<a title='Email' href={`mailto:${testimonial.email}`} ><IoMail /></a>)}

                                </div>
                            </h4>
                        </div>

                        <h5 className="p-text">
                            <FaNetworkWired /> {testimonial.position}
                        </h5>
                        <h5 className="p-text">
                            <FaBuilding /> {testimonial.company}
                        </h5>
                    </div>
                </div>
            </div>

            <div className="app__testimonial-btns app__flex">
                <div className="app__flex" onClick={() => onHandleLeftClick()}>
                    <HiChevronLeft />
                </div>

                <div className="app__flex" onClick={() => onHandleRightClick()}>
                    <HiChevronRight />
                </div>
            </div>
        </>
    )
}
import { NavigationDots, SocialMedia } from "../components"


export const SectionWrapper = ({ children, idName, classNames }) => {

    return (
        <section id={idName} className={`app__container ${classNames}`}>
            <SocialMedia />
            <div className="app__wrapper app__flex">
                {children}
                <div className='copyright'>
                    <p className='p-text'> &copy; 2024 - Jhoset NG, All Rights Reserved </p>
                </div>
            </div>
            <NavigationDots active={idName} />
        </section>
    )
}
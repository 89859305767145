import { useState } from "react";
import { sectionList } from "../constants"
import { motion } from 'framer-motion';


export const NavigationDots = ({ active }) => {
    const [isVisible, setIsVisible] = useState(true);

    const handleClick = () => {
        setIsVisible(false);
        setTimeout(() => {
            setIsVisible(true);
        }, 1000);
    };

    return (
        <>
            <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5, ease: 'easeIn' }}
                className="app__navigation">
                {
                    isVisible && sectionList.map((section, index) => (
                        <a
                            onClick={handleClick}
                            aria-label={section}
                            key={section + index}
                            className="app__navigation-dot"
                            style={active === section ? { backgroundColor: 'var(--secondary-color)' } : {}}
                            href={`#${section}`}
                        />
                    ))
                }
            </motion.div>

        </>

    )

}
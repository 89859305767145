import { motion } from 'framer-motion';
import {urlFor} from '../../../data/client';


export const AboutCard = ({ about }) => {
    return (
        <>
            <motion.div
                whileInView={{ opacity: 1 }}
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.5, type: 'tween' }}
                className="app__profile-item"
            >

                <img src={urlFor(about.imgUrl)} alt={about.title} />
                <h2 className="bold-text" style={{ marginTop: 20 }} >  {about.title}  </h2>
                <p className="p-text" style={{ marginTop: 20 }} >  {about.description}  </p>

            </motion.div>
        </>
    )
}
import "./Work.scss";
import { motion } from 'framer-motion';

import { SectionWrapper, MotionWrap } from "../../wrapper";
// import { useEffect, useState } from "react";
import { useFetchSanity } from "../../hooks/useFetchSanity";
import { ProjectCard } from "./components/ProjectCard";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

export const Work = () => {

    // const defaultType = "Frontend"
    // const { data: projectTypes } = useFetchSanity(`*[_type == "projectType"]`)
    const { data: projectList } = useFetchSanity(`*[_type == "project"] {_id, name, description, demoUrl, sourceCodeUrl, imgUrl , type-> }`)

    // const [activeFilter, setActiveFilter] = useState(defaultType);
    // const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 })
    // const [filterProjectList, setFilterProjectList] = useState(projectList)

    // useEffect(() => {
    //     setFilterProjectList(projectList.filter(project => project.type.name === defaultType))
    // }, [projectList])

    // const handleWorkFilter = (projectType) => {
    //     setActiveFilter(projectType);
    //     setAnimateCard([{ y: 100, opacity: 0 }])

    //     setTimeout(() => {
    //         setAnimateCard([{ y: 0, opacity: 1 }])
    //         if (projectType === 'All') {
    //             setFilterProjectList(projectList)
    //         } else {
    //             setFilterProjectList(projectList.filter(project => project.type.name === projectType))
    //         }
    //     }, 500)
    // }


    return (
        <SectionWrapper idName={'work'} classNames={`app__primarybg`}>
            <MotionWrap classNames={`app__works`}>
                <h2 className="head-text">My <span>Recent</span>  Personal <span>Projects </span>
                </h2>
                <p className="p-text section-description">Explore my personal projects, where I've poured my efforts and creativity.</p>
                {/* <div className="app__work-filter">
                    {
                        projectTypes.map(({ _id, name, abbreviation }, index) => (
                            <div
                                key={_id}
                                onClick={() => handleWorkFilter(name)}
                                className={`app__work-filter-item app__flex p-text ${activeFilter === name ? 'item-active' : ''}`}
                            >
                                {name}
                            </div>
                        ))
                    }
                </div> */}
                {/* <motion.div
                    animate={animateCard}
                    transition={{ duration: 0.5, delayChildren: 0.5 }}
                    className="app__work-portfolio"
                >
                    {
                        filterProjectList.length ? (
                            filterProjectList.map((project) => (
                                <ProjectCard key={project._id} project={project} />
                            ))
                        ) :
                            (
                                <motion.div whileInView={{ opacity: [0, 1] }}
                                    transition={{ duration: 0.3, ease: 'easeInOut' }}>
                                    <h3 style={{ color: '#6b7688', fontSize: '1.5rem' }} className="head-text">  Still Working On it</h3>
                                    <img style={{ width: '100%', maxHeight: '400px' }} src="./wip.png" width={390} alt="WIP" />
                                </motion.div>
                            )
                    }
                </motion.div> */}
                <div className="app__work-portfolio swiper-container">
                    <Swiper

                        slidesPerView={1}
                        spaceBetween={5}
                        autoplay={{ delay: 5000 }}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            900: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },

                            1600: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                            },

                            2000: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                        }}
                        modules={[Pagination, Autoplay]}
                    >
                        {
                            projectList.length ? (
                                projectList.map((project) => (
                                    <SwiperSlide key={project._id}>
                                        <ProjectCard project={project} />
                                    </SwiperSlide>
                                ))
                            ) :
                                (
                                    <motion.div whileInView={{ opacity: [0, 1] }}
                                        transition={{ duration: 0.3, ease: 'easeInOut' }}>
                                        <h3 style={{ color: '#6b7688', fontSize: '1.5rem' }} className="head-text">  Still Working On it</h3>
                                        <img style={{ width: '100%', maxHeight: '400px' }} src="./wip.png" width={390} alt="WIP" />
                                    </motion.div>
                                )
                        }
                    </Swiper>
                </div>

                <a
                    href={'https://github.com/jhoset'}
                    target="_blank"
                    rel="noreferrer"
                    className={`app__work-more-button`}
                >
                    See more on GitHub
                </a>



            </MotionWrap>
        </SectionWrapper>
    )

}
import "./Testimonial.scss";
import { motion } from 'framer-motion';
import { MotionWrap, SectionWrapper } from "../../wrapper";
import { useState } from "react";
import { urlFor } from '../../data/client'
import { TestimonialCard } from "./components/TestimonialCard";
import { sections } from "../../constants/sections";
import { useFetchSanity } from "../../hooks/useFetchSanity";

export const Testimonial = () => {
    const { data: testimonialList } = useFetchSanity(`*[_type == "testimonial"] | order(order asc) { _id, name,linkedInUrl, position, company, imgUrl, feedback, email }`);
    const { data: brandList } = useFetchSanity(`*[_type == "brand"]`)

    const [currentIndex, setCurrentIndex] = useState(0)
    const currentTestimonial = testimonialList[currentIndex];


    const onHandleLeftClick = () => {
        setCurrentIndex((value) => {
            if (value === 0) return testimonialList.length - 1;
            return value -= 1;
        })
    }

    const onHandleRightClick = () => {
        setCurrentIndex((value) => {
            if (value === testimonialList.length - 1) return 0;
            return value += 1;
        })
    }

    return (
        <SectionWrapper idName={sections.testimonials} classNames={`app__primarybg`}>
            <MotionWrap classNames={`app__testimonial`}>
                <>
                    <h2 className="head-text"> Testimonials </h2>
                    <p className="p-text section-description">
                        Feedback and recommendations provided by people I've had the opportunity to work with
                    </p>
                    {
                        testimonialList.length && (
                            <TestimonialCard
                                testimonial={currentTestimonial}
                                onHandleLeftClick={onHandleLeftClick}
                                onHandleRightClick={onHandleRightClick}
                            />
                        )
                    }
                    <div className="app__testimonial-brands app__flex">
                        {
                            brandList.map((brand) => (
                                <motion.div
                                    whileInView={{ opacity: [0, 1] }}
                                    transition={{ duration: 0.5, type: 'tween' }}
                                    key={brand._id}
                                >
                                    <img src={urlFor(brand.imgUrl)} alt={brand.name} />
                                </motion.div>
                            ))
                        }
                    </div>
                </>
            </MotionWrap>
        </SectionWrapper >
    )
}
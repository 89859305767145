import { urlFor } from '../../../data/client'
import { motion } from 'framer-motion';

export const SkillItem = ({ skill }) => {
    return (
        <>
            <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0 }}
                className="app__skills-item app__flex"
            >
                <div className="app__flex" style={{ backgroundColor: skill.bgColor }}>
                    <img src={urlFor(skill.imgUrl)} alt={skill.name} />
                </div>
                <p className="p-text" > {skill.name} </p>
            </motion.div>
        </>
    )
}
import "./Navbar.scss";
import { images, sectionList, sections } from "../../constants";
import { HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import { useState } from "react";
import { FaHome } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";
import { GrProjects } from "react-icons/gr";
import { GiSkills } from "react-icons/gi";
import { VscFeedback } from "react-icons/vsc";
import { MdContactMail } from "react-icons/md";
import { IoMenu } from "react-icons/io5";
export const Navbar = () => {
    const [toggle, setToggle] = useState(false);


    return (
        <nav className="app__navbar">
            <div className="app__navbar-logo">
                <img src={images.logo} alt="logo" />
            </div>
            <ul className="app__navbar-links">
                {
                    sectionList.map(item => (
                        <li className="app__flex p-text " key={`top-nav-${item}`}>
                            <a className="link" href={`#${item}`}> {item} </a>
                        </li>
                    ))
                }
            </ul>

            <div className="app__navbar-menu">
                <IoMenu onClick={() => setToggle(true)} />
                {
                    toggle && (
                        <motion.div
                            initial={{ x: '100%' }}
                            whileInView={{ x: [300, 0] }}
                            transition={{ duration: 0.5, ease: 'easeOut' }}
                        >
                            <HiX aria-label="Close Menu" title="Close Menu" onClick={() => setToggle(false)} />
                            <ul>
                                <li className="menu-item">
                                    <FaHome />
                                    <a href={`#${sections.home}`} onClick={() => setToggle(false)}> {sections.home} </a>
                                </li>
                                <li className="menu-item">
                                    <FaQuestionCircle />
                                    <a href={`#${sections.about}`} onClick={() => setToggle(false)}> {sections.about} </a>
                                </li>
                                <li className="menu-item">
                                    <GrProjects />
                                    <a href={`#${sections.work}`} onClick={() => setToggle(false)}> {sections.work} </a>
                                </li>
                                <li className="menu-item">
                                    <GiSkills />
                                    <a href={`#${sections.skills}`} onClick={() => setToggle(false)}> {sections.skills} </a>
                                </li>
                                <li className="menu-item">
                                    <VscFeedback />
                                    <a href={`#${sections.testimonials}`} onClick={() => setToggle(false)}> {sections.testimonials} </a>
                                </li>
                                <li className="menu-item">
                                    <MdContactMail />
                                    <a href={`#${sections.contact}`} onClick={() => setToggle(false)}> {sections.contact} </a>
                                </li>
                            </ul>
                            <p className='p-text'> &copy; 2024 - Jhoset NG, All Rights Reserved </p>
                        </motion.div>
                    )
                }
            </div>
        </nav>
    )
}
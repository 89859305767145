import { useState } from "react";
import { useForm } from "../../../hooks/useForm";
import { client } from "../../../data/client";

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const isValidEmail = (value) => {
    if (emailRegex.test(value)) {
        return true;
    }
    return false;
}
export const ContactForm = () => {
    const [ifFormSubmitted, setIfFormSubmitted] = useState(false)
    const [validationErrors, setValidationErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { name, email, message, formState, onInputChange } = useForm({ name: '', email: '', message: '' })
    let errors = []

    const handleSubmit = async () => {
        if (isLoading) return;
        setIsLoading(true)
        if (isFormInvalid()) {
            setValidationErrors(errors)
            setIsLoading(false);
            return;
        }
        setValidationErrors([]);

        const contact = {
            _type: 'contact',
            ...formState
        }
        const resp = await client.create(contact)

        if (resp) {
            setIfFormSubmitted(true);
        }
        setIsLoading(false);
    }

    const getErrorByName = (name) => {
        return validationErrors.find(error => error.field === name)?.error || undefined;
    }

    const isFormInvalid = () => {
        errors = [];
        if (!name || !name.length) {
            errors.push({ field: 'name', error: 'Name is required' });
        }
        if (!email || !name.length) {
            errors.push({ field: 'email', error: 'Email is required' });
        }
        if (email && email.length && !isValidEmail(email)) {
            errors.push({ field: 'email', error: 'Email is not valid' });
        }
        if (!message || !message.length) {
            errors.push({ field: 'message', error: 'Message is required' });
        }
        return !!errors.length;
    }

    return (
        <>
            {
                !ifFormSubmitted ? (
                    <div className="app__footer-form app__flex">
                        <div className="app__flex">
                            <input autoComplete="off" style={{ border: `${!!getErrorByName("name") ? '1px solid  red' : ''}` }} className="p-text" type="text"
                                placeholder="Your Name" name="name" value={name} onChange={onInputChange} />
                        </div>
                        <div className="app__flex">
                            <input style={{ border: `${!!getErrorByName("email") ? '1px solid  red' : ''}` }} className="p-text" type="text"
                                placeholder="Your Email" name="email" value={email} onChange={onInputChange} />
                        </div>
                        <div>
                            <textarea autoComplete="off" style={{ border: `${!!getErrorByName("message") ? '1px solid  red' : ''}` }} className="p-text" placeholder="Your Message"
                                name="message" value={message} onChange={onInputChange} ></textarea>
                        </div>
                        {validationErrors.length > 0 && (<div style={{ cursor: 'default', padding: '0.5rem 1rem', backgroundColor: `#fccdd1`, color: '#390509' }} className="errorMessage">
                            One or more fields are invalid, please enter valid data and try again :D </div>)}
                        <button type="button" className="p-text" onClick={handleSubmit}>
                            {isLoading ? (<span className="loader"> <div className="lds-dual-ring"></div> Sending . . .</span>) : 'Send Message'}
                        </button>
                    </div>
                ) : (
                    <div>
                        <div>
                            <h3 style={{ color: '#6b7688', fontSize: '1.5rem' }} className="head-text"> Thanks for getting in touch! </h3>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src="./contact.png" alt="Thanks" />
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export const sections = {
    home: 'home',
    about: 'about',
    work: 'work',
    skills: 'skills',
    testimonials: 'testimonials',
    contact: 'contact',
}

export const  sectionList = [
    sections.home,
    sections.about,
    sections.work,
    sections.skills,
    sections.testimonials,
    sections.contact
]

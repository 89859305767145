import "./Footer.scss";
import { images, sections } from '../../constants';
import { MotionWrap, SectionWrapper } from "../../wrapper";
import { ContactForm } from "./components/ContactForm";

export const Footer = () => {

    return (
        <SectionWrapper idName={sections.contact} classNames={`app__whitebg`}>
            <MotionWrap classNames={`app__footer`}>
                <>
                    <h2 className="head-text"> <span>Take  </span> a coffee & <span>chat</span> with me </h2>

                    <div className="app__footer-cards">
                        <a href="mailto:adm.frontdev@gmail.com" className="app__footer-card">
                            <img src={images.email} alt="Email" />
                            <span className="p-text"> adm.frontdev@gmail.com </span>
                        </a>
                        <a href="tel:+51961297105" className="app__footer-card">
                            <img src={images.mobile} alt="Email" />
                            <span className="p-text"> Contact on WhatsApp </span>
                        </a>
                    </div>

                    <ContactForm />
                </>
            </MotionWrap>
        </SectionWrapper>
    )

}
import "./Skills.scss";

import { motion } from 'framer-motion';
import { useFetchSanity } from "../../hooks/useFetchSanity";
import { SkillItem } from "./components/SkillItem";
import { ExperienceItem } from "./components/ExperienceItem";
import { sections } from "../../constants/sections";
import { MotionWrap, SectionWrapper } from "../../wrapper";


export const Skills = () => {


    const { data: skillList } = useFetchSanity(`*[_type == "skill"]`);
    const { data: experienceList } = useFetchSanity(`*[_type == "experience"] | order(date desc) {_id, date, works[]-> }`);


    return (
        <SectionWrapper idName={sections.skills} classNames={`app__whitebg`}>
            <MotionWrap classNames={`app__skills`}>
                <h2 className="head-text"> Skills <span>&</span> Experience </h2>
                <p className="p-text section-description">
                    During my developer journey, I've gained proficiency in various tools <br /> some of the most relevant ones include:
                </p>
                <div className="app__skills-container">
                    <motion.div className="app__skills-list">
                        {
                            skillList.map((skill) => (
                                <SkillItem key={skill._id} skill={skill} />
                            ))
                        }
                    </motion.div>

                    <motion.div className="app__skills-exp">
                        {
                            experienceList.map((experience) => (
                                <ExperienceItem key={experience._id} experience={experience} />
                            ))
                        }
                    </motion.div>
                </div>
            </MotionWrap>
        </SectionWrapper >
    )

}
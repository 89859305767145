import { motion } from 'framer-motion';
import { urlFor } from '../../../data/client'
import { AiFillEye, AiFillGithub } from 'react-icons/ai';

export const ProjectCard = ({ project }) => {
    return (
        <>
            <div className="app__work-item app__flex">

                <div className="app__work-img app__flex">
                    <motion.img src={urlFor(project.imgUrl)} alt={project.name}
                        whileInView={{ opacity: [0, 1] }}
                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                    >

                    </motion.img>
                    <motion.div
                        whileHover={{ opacity: [0, 1] }}
                        transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                        className="app__work-hover app__flex"
                    >
                        {
                            project.demoUrl && (
                                <a href={project.demoUrl} target="_blank" rel="noreferrer">
                                    <motion.div
                                        whileInView={{ scale: [0, 1] }}
                                        transition={{ duration: 0.25 }}
                                        className="app__flex"
                                    >
                                        <AiFillEye />
                                    </motion.div>
                                </a>
                            )
                        }
                        {
                            project.sourceCodeUrl && (
                                <a href={project.sourceCodeUrl} target="_blank" rel="noreferrer">
                                    <motion.div
                                        whileInView={{ scale: [0, 1] }}
                                        transition={{ duration: 0.25 }}
                                        className="app__flex"
                                    >
                                        <AiFillGithub />
                                    </motion.div>
                                </a>
                            )
                        }
                    </motion.div>
                </div>
                <div className="app__work-content app__flex">
                    <h4 className="bold-text"> {project.name} </h4>
                    <p className="p-text description" style={{ marginTop: 10 }}>
                        {project.description}
                    </p>
                    <div className="app__work-tag app__flex">
                        <p className="p-text"> {project.type.name}</p>
                    </div>
                </div>

            </div>
        </>
    )
}
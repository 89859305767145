import "./Header.scss";
import { images, sections } from "../../constants";
import { motion } from "framer-motion"
import { SectionWrapper } from "../../wrapper";
import { useEffect } from "react";


const scaleVariants = {
    whileInView: {
        scale: [0, 1],
        opacity: [0, 1],
        transition: {
            duration: 1,
            ease: 'easeInOut'
        }
    }
}

export const Header = () => {

    //? PARALLAX

    useEffect(() => {
        const parallaxItems = document.querySelectorAll("[data-parallax-item]");
        let x, y;
        window.addEventListener("mousemove", (event) => {
            x = (event.clientX / window.innerWidth * 10) - 5;
            y = (event.clientY / window.innerHeight * 10) - 5;

            //? reverse the number eg. 20 -> -20, -5 -> 5;
            x = -x;
            y = -y;

            for (let i = 0; i < parallaxItems.length; i++) {
                x = x * Number(parallaxItems[i].dataset.parallaxSpeed)
                y = y * Number(parallaxItems[i].dataset.parallaxSpeed)
                parallaxItems[i].style.transform = `translate3d(${x}px, ${y}px, 0px)`;
            }
        })
    }, [])

    return (
        <SectionWrapper idName={sections.home}>
            <div id="home" className="app__header app__flex">
                <motion.div
                    whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}
                    className="app__header-info"
                >
                    <div className="app__header-badge">
                        <div data-parallax-item
                            data-parallax-speed="1.75" className="badge-cmp app__flex">
                            <span>👨🏻‍💻</span>
                            <div style={{ marginLeft: '20px' }}>
                                <p className="p-text">Hello, I'm</p>
                                <h1 className="head-text">
                                    Jhoset
                                </h1>
                            </div>
                        </div>

                        <div
                            data-parallax-item
                            data-parallax-speed="0.5"
                            className="tag-cmp tag-desc app__flex">
                            <p className="p-text">Passionate about innovation and continuous learning. I strongly believe in the importance of collaboration and teamwork to drive project success.</p>
                        </div>

                        <div
                            data-parallax-item
                            data-parallax-speed="1.75"
                            className="tag-cmp app__flex role">
                            <p className="p-text">Software Engineer &</p>
                            <p className="p-text">Full Stack Developer</p>
                        </div>
                    </div>

                </motion.div>

                <motion.div
                    whileInView={{ opacity: [0, 1] }}
                    transition={{ duration: 0.5, delayChildren: 0.5 }}
                    className="app__header-img"
                >
                    <img src="./profile.png" alt="Profile" />

                    <motion.img
                        whileInView={{ scale: [0, 1] }}
                        transition={{ duration: 1, ease: 'easeInOut' }}
                        src={images.circle}
                        alt="Profile_circle"
                        className="overlay_circle"
                    />
                    <motion.div
                        whileInView={{ y: [100, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.5 }}
                        className="app__header-info description"
                    >
                        <div data-parallax-item
                            data-parallax-speed="0.5" className="app__header-badge">

                            <div className="tag-cmp app__flex">
                                <p
                                    className="p-text">
                                    Passionate about innovation and continuous learning. I strongly believe in the importance of collaboration and teamwork to drive project success.
                                </p>
                            </div>
                        </div>
                    </motion.div>

                </motion.div>
                <div className="app__header-circles">
                    {

                        ["./securecode.png", "./dev.png", "agile.png"].map((img, index) => (

                            <motion.div
                                key={index}
                                data-parallax-item
                                data-parallax-speed={`${index + 0.75}`}
                                variants={scaleVariants}
                                whileInView={scaleVariants.whileInView}
                            >
                                <div className="circle-cmp app__flex" key={`circle-${index}`}>
                                    <img src={img} alt="Tech Stack" />
                                </div>
                            </motion.div>
                        ))

                    }
                </div>
            </div>


        </SectionWrapper>
    )
}
import "./About.scss";
import { SectionWrapper, MotionWrap } from "../../wrapper";
import { sections } from "../../constants";
import { AboutCard } from "./components/AboutCard";
import { useFetchSanity } from "../../hooks/useFetchSanity";


export const About = () => {
    const { data: aboutList } = useFetchSanity(`*[_type == "about"]`);

    return (
        <SectionWrapper idName={sections.about} classNames={`app__whitebg`}>
            <MotionWrap classNames={`app__about`}>
                <h2 className="head-text">I know that <span>Quality Software </span><br />means <span>Business Success</span>
                </h2>
                <p className="p-text section-description">
                    Let's Collaborate to Build Exceptional Products. <br /> As your ally  I'll bring skills in:
                </p>
                <div className="app__profiles">
                    {
                        aboutList.map((about, index) => (
                            <AboutCard about={about} key={about.title + index} />
                        ))
                    }
                </div>

            </MotionWrap>
        </SectionWrapper>
    )

}
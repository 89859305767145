import { ImLinkedin } from "react-icons/im";
import { FaGithub, FaYoutube  } from 'react-icons/fa';
import { SiLeetcode } from "react-icons/si";
import { Tooltip } from 'react-tooltip'
import { motion } from 'framer-motion';

export const SocialMedia = () => {

    const linkedIn = "https://www.linkedin.com/in/jhoset-nina-b15220155/";
    const gitHub = "https://github.com/jhoset";
    const leetCode = "https://leetcode.com/164245/";
    const youTube = "https://www.youtube.com/channel/UC_EULBG3OO7XDtD7N_II6Hg";
    return (
        <>
            <div className="app__social">
                <a href={linkedIn}
                    target="_blank"
                    rel="noreferrer">
                    <motion.div
                        whileInView={{ scale: [0, 1] }}
                        transition={{ duration: 0.25 }}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="LinkedIn"
                        data-tooltip-place='right'
                    >
                        <ImLinkedin />
                    </motion.div>
                </a>
                <a href={youTube}
                    target="_blank"
                    rel="noreferrer">
                    <motion.div
                        whileInView={{ scale: [0, 1] }}
                        transition={{ duration: 0.25 }}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="YouTube"
                        data-tooltip-place='right'
                    >
                        <FaYoutube />
                    </motion.div>
                </a>
                <a href={gitHub}
                    target="_blank"
                    rel="noreferrer">
                    <motion.div
                        whileInView={{ scale: [0, 1] }}
                        transition={{ duration: 0.25 }}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="GitHub"
                        data-tooltip-place='right'
                    >
                        <FaGithub />
                    </motion.div>
                </a>
                <a href={leetCode}
                    target="_blank"
                    rel="noreferrer">
                    <motion.div
                        whileInView={{ scale: [0, 1] }}
                        transition={{ duration: 0.25 }}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="LeetCode"
                        data-tooltip-place='right'
                    >
                        <SiLeetcode />
                    </motion.div>
                </a>


            </div>

            <Tooltip id="my-tooltip" />
        </>

    )
}
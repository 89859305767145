import { Navbar } from "../components"
import { About, Footer, Header, Skills, Testimonial, Work } from "../sections"

export const LandingPage = () => {
    return (
        <div className="app">
            <Navbar />
            <Header />
            <About />
            <Work />
            <Skills />
            <Testimonial />
            <Footer />
        </div>
    )
}
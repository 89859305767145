import { useEffect, useState } from "react"
import { client } from "../data/client"


export const useFetchSanity = (query) => {

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const getAsyncDataByDocName = async () => {
        try {
            const newData = await client.fetch(query);
            setData(newData);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getAsyncDataByDocName();
    }, [])

    return {
        data,
        isLoading
    }
}
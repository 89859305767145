import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.svg';

import frontdev from "../assets/frontdev.png"
import contact from "../assets/contact.png";
import codelines from "../assets/codelines.png";
import wip from "../assets/wip.png";

export const images = {
    wip,
    codelines,
    contact,
    frontdev,
    email,
    mobile,
    circle,
    logo
};